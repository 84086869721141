.App {
  display: flex;
  text-align: center;
  height: 100%;
  padding: 0 10% 10% 10%;
  background-image: url("./background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1020px) {
  body,
  html,
  #root {
    height: 100%;
  }

  .App {
    flex-direction: column;
  }
}
