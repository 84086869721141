.squat-photo {
  height: 75%;
}

.photo-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1020px) {
  .squat-photo {
    height: 100%;
    max-height: 70vh;
    max-width: 70vw;
  }

  .photo-container {
    flex-basis: 35%;
    order: 1;
  }
}
