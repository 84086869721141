label {
  display: block;
  margin-top: 1rem;
}

input {
  display: block;
  width: 270px;
  height: 30px;
}

.signup-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 10% 10% 10%;
  background-color: #101032;
}

.small-text {
  font-family: "Teko", sans-serif;
  font-size: 1.25rem;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
}

.white-text {
  color: white;
}

.submit-button {
  font-family: "Teko", sans-serif;
  font-size: 1.1rem;
  letter-spacing: 0.15em;
  font-weight: 500;
  margin-top: 2rem;
  width: 280px;
  height: 35px;
  background-color: #ed4252;
  color: white;
  border-radius: 8px;
  border: none;
}

.submit-button:hover {
  cursor: pointer;
  transform: scale(1.05);
}

@media only screen and (max-width: 1020px) {
  body,
  html,
  #root {
    height: 100%;
  }

  .App {
    flex-direction: column;
  }
}
