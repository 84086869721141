@font-face {
  font-family: "Regbout-Regular";
  src: url("../../fonts/Regbout-Regular.otf") format("opentype");
}

body,
html,
#root {
  height: 100%;
}
